@tailwind base;
@tailwind components;
@tailwind utilities;


.Title {
  @apply uppercase text-Heading max_md:text-HeadingTab text-Blue font-HandelGothic;
}

.SubTitle {
  @apply text-paraText font-Roborto max_md:text-paraTextTab;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.WhyUsCard:hover {
  /* border-color: #4A55A2; */
}
.WhyUsCard:hover .icon{
  transition: top ease-in-out 0.5s;
}
.WhyUsCard:hover .icon{
  /* -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.1);
  box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.1); */
  top: -46%;
}
.card_class{
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
.card_class:hover{
  top: -10px;

}

.WhyUsCard:hover :first-child,
.WhyUsCard:hover p {
  /* border-color: #4A55A2; */
  color: #4A55A2;
 
}

.WhyUsCard:hover svg path {
  fill: #4A55A2;
}

::-webkit-scrollbar {
  display: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.custom-class .swiper-pagination-bullets {
  display: none;
}

.circle {
  position: relative;
  margin: 20px;
  height: 300px;
  width: 300px;
  background-color: #4A55A2;
  border-radius: 50%;
}

.circle:before {
  position: absolute;
  content: '';
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  border: 2px dashed black;
  top: -12px;
  left: -13px;
  border-radius: inherit;
  animation: spin 10s linear infinite;
}

@keyframes spin { 
  100% { 
    transform: rotateZ(360deg); 
  }
}

.circle1 {
  position: relative;
  margin: 20px;
  height: 220px;
  width: 220px;
  background-color: #4A55A2;
  border-radius: 50%;
}

.circle1:before {
  position: absolute;
  content: '';
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  border: 2px dashed black;
  top: -12px;
  left: -13px;
  border-radius: inherit;
  animation: spin 10s linear infinite;
}

@keyframes spin { 
  100% { 
    transform: rotateZ(360deg); 
  }
}


.myswiper_custom .swiper-pagination{
  bottom: -5px !important;
}
