@font-face {
  font-family: 'HandelGothic BT';
  src: url(./utils/font/HandelGothic.ttf);
}
@font-face {
  font-family: 'Roborto';
  src: url(./utils/font/Roboto-Regular.ttf);
}
@font-face {
  font-family: 'Lato';
  src: url(./utils/font/Lato-Regular.ttf);
}